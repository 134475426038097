const config = {
  apiGateway: {
    REGION: 'us-east-1',
    INTERNAL_API_URL: 'https://nigeji6c42.execute-api.us-east-1.amazonaws.com/prod',
    TEAM_API_URL: 'https://n69gxiqsqj.execute-api.us-east-1.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.2',
    HOSTNAME: 'https://teams-service.rossing.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.rossing.forwoodsafety.com',
    WEBSOCKET: 'wss://407rcjdf6d.execute-api.us-east-1.amazonaws.com/prod'
  },
};

export default config;
